import React, {useState} from 'react'
import GalleryBackground from '../../components/galleryBackground'
import columnRo from '../../images/rollovers/column-ro.png'
import LongImgModal from '../../components/longImgModal'
import churls from '../../images/writing/columns/the-churls-and-the-charitable.jpg'
import pollAttacks from '../../images/writing/columns/poll-attacks.jpg'
import pirchforks from '../../images/writing/columns/peachtree-pirchforks.jpg'
import expedience from '../../images/writing/columns/expedience.jpg'
import edgeStumbles from '../../images/writing/columns/edge-stumbles.jpg'
import drumsticks from '../../images/writing/columns/drumsticks.jpg'
import wildlife from '../../images/writing/columns/anti-wildlife-wall.jpg'
import marijuana from '../../images/writing/columns/medical-marijuana.jpg'
import "../layout.css"


export default function Columns() {
    const [modalState, setModalState] = useState({imgSrc:undefined, open:false})

    const handleClose = () => {
        setModalState(oldState => [{...oldState, open:false}])
    }
    return (
        <GalleryBackground title="Columns" imgSrc={columnRo}>
            <div className="columns-container">
                <a href="/the-churls-and-the-charitable.pdf">
                    <h2>the churls & the charitable</h2>
                </a>
                <a href="/poll-attacks.pdf">
                    <h2>the new poll attacks</h2>
                </a>
                <a href="/peachtree-pitchforks.pdf">
                    <h2>peachtree city pitchforks</h2>
                </a>
                <a href="/medical-marijuana.pdf">
                    <h2>time to weed the garden</h2>
                </a>
                <a href="/expedience.pdf">
                    <h2>the expedience of seeing peachtree city</h2>
                </a>
                <a href="/edge-stumbles.pdf">
                    <h2>the edge stumbles</h2>
                </a>
                <a href="/drumsticks.pdf">
                    <h2>fayette drumsticks</h2>
                </a>
                <a href="/anti-wildlife-wall.pdf">
                    <h2>pro-wall equals anti-wildlife</h2>
                </a>
            </div>
            <LongImgModal modalState={modalState} handleClose={handleClose} />
        </GalleryBackground>
    )
}
